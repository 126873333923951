import type { Immutable } from 'immer';

import type { DateString, TimeString } from '../../../services/common';
import { isDateString } from '../../../services/common';
import type { ApiElectionDay } from '../../../services/lbj-shared-service';
import type {
  ApiVolunteerAvailability,
  ApiVolunteerAvailabilityGroup,
  ApiVolunteerAvailabilityTimeOption,
  NewApiVolunteerAvailability,
} from '../../../services/volunteer-availability-service';

import { assertUnreachable } from '../../../utils/types';
import type { AssignmentUser } from '../assignment-state';
import { getVolunteerAttributes } from '../assignment-utils';

import type { RowParseResult } from './VolunteerAvailabilityDialogResults';

export type TwentyFourHourRange = `${number}-${number}`;
export type FriendlyAvailabilityAlias = 'am' | 'pm' | 'all day';

/**
 * We can’t properly type this out as a comma-separated list of
 * {@link TwentyFourHourRange} values, so just do one and then whatever after is
 * fine.
 */
export type DayGridCellRange = `${TwentyFourHourRange}${string}`;

/**
 * Type for the values of the day grid cells.
 *
 * Will either be friendly alias or a comma-separated list of
 * {@link TwentyFourHourRange} values (or empty string).
 */
export type DayGridCellValue =
  | FriendlyAvailabilityAlias
  | DayGridCellRange
  | '';

/**
 * Type of the CSV that we generate for “download availability,” as well as the
 * expected type for day-grid uploads.
 *
 * All the fields are marked as optional, but the download will specify all of
 * them, and on upload at least one of `LBJ ID`, `Email`, or `VAN ID` must be
 * specified in order to do matching to volunteers.
 */
export type DayGridRow = {
  'LBJ ID'?: number;
  'First Name'?: string;
  'Last Name'?: string;
  Email?: string;
  'VAN ID'?: string;
} & {
  [d: DateString]: DayGridCellValue;
};

/**
 * Our parsed interpretation of a {@link DayGridRow}. `null` is used when the
 * column was not present in the source data, or also, in the case of the
 * numeric entries, if the cell was left blank.
 */
export type ParsedDayGridRow = {
  vanId: number | null;
  lbjId: number | null;
  email: string | null;

  firstName: string | null;
  lastName: string | null;

  availabilityByDay: {
    [d: DateString]: {
      time: ApiVolunteerAvailabilityTimeOption;
      customTimes: Array<[TimeString, TimeString]> | null;
    };
  };
};

/**
 * Generates an array of row objects for volunteers, with columns for user
 * information and also each day.
 *
 * If availability data / group is provided, restricts the output to just those
 * people on those days.
 *
 * Otherwise, returns a template for all early vote people on all days of early
 * vote.
 */
export function makeAvailabilityCsvExport(
  usersById: Immutable<Map<number, AssignmentUser>>,
  data:
    | {
        availabilityByUserId: Immutable<
          Map<number, ApiVolunteerAvailability[]>
        >;
        groupId: number;
      }
    | {
        days: ApiElectionDay[];
      }
): DayGridRow[] {
  /** Dates for the columns. Either those from the availability or EV days. */
  let outputDatesSet: Set<DateString>;

  /**
   * Users who are in this group, or just everybody who is an EV poll observer.
   * We’ll generate one row for each.
   */
  let outputUserIds: number[];

  if ('groupId' in data) {
    outputDatesSet = new Set(
      [...data.availabilityByUserId.values()]
        .flatMap((availArr) =>
          availArr.filter((a) => a.availability_group_id === data.groupId)
        )
        .map((a) => a.date)
    );

    outputUserIds = [...data.availabilityByUserId.entries()]
      .filter(
        ([_, availArr]) =>
          !!availArr.find((a) => a.availability_group_id === data.groupId)
      )
      .map(([userId]) => userId);
  } else if ('days' in data) {
    outputDatesSet = new Set(
      data.days.filter((d) => d.is_early_vote).map((d) => d.date)
    );

    outputUserIds = [...usersById.values()]
      .filter((u) => getVolunteerAttributes(u).isEvVolunteer)
      .map((u) => u.id);
  } else {
    assertUnreachable(data);
  }

  const sortedOutputDates = [...outputDatesSet];
  // ISO8601 sorts lexographically
  sortedOutputDates.sort();

  return outputUserIds.map((userId) => {
    // This _should_ exist but no guarantees, so play it safe below.
    const user = usersById.get(userId);

    return {
      'LBJ ID': userId,
      'First Name': user?.first_name ?? '',
      'Last Name': user?.last_name ?? '',
      Email: user?.email ?? '',
      'VAN ID': user?.van_id?.toString() ?? '',

      // Add in columns for each date we have availability for. Note that the
      // columns need to be consistent across every row.
      ...Object.fromEntries(
        sortedOutputDates.map((date) => {
          // Only use the first availability in this group for this day, since
          // there should only be one anyway.
          let availInGroupForDay:
            | Immutable<ApiVolunteerAvailability>
            | undefined;

          if ('groupId' in data) {
            availInGroupForDay = data.availabilityByUserId
              .get(userId)
              ?.find(
                (a) =>
                  a.availability_group_id === data.groupId && a.date === date
              );
          } else if ('days' in data) {
            availInGroupForDay = undefined;
          } else {
            assertUnreachable(data);
          }

          return [
            date,
            availInGroupForDay
              ? availabilityToTimeString(availInGroupForDay)
              : '',
          ];
        })
      ),
    };
  });
}

/**
 * Returns a friendier time string for availability. Rather than “custom”
 * returns a comma-separated list of the start/end ranges. Also replaces the
 * underscore in `all_day` with a space, for aesthetic reasons.
 */
function availabilityToTimeString(
  availability: Pick<
    Immutable<ApiVolunteerAvailability>,
    'time' | 'custom_times'
  >
): DayGridCellValue {
  switch (availability.time) {
    case 'am':
    case 'pm':
      return availability.time;

    case 'all_day':
      return 'all day';

    case 'custom':
      return (availability.custom_times
        ?.map(
          ([start, end]) => `${timeStringTo24h(start)}-${timeStringTo24h(end)}`
        )
        .join(', ') ?? '') as DayGridCellRange | '';

    default:
      assertUnreachable(availability.time);
  }
}

/**
 * Converts a {@link TimeString} ISO8601 format into the 4-digit 24hr time
 * format we’ve been using for imports.
 */
function timeStringTo24h(t: TimeString): string {
  const [hrs, mins, _secs] = t.split(':');
  return `${hrs}${mins}`;
}

/**
 * Parses what we expect to be a {@link DayGridRow} from the CSV into a
 * {@link ParsedDayGridRow}.
 *
 * Note that this does not error if the user-matching columns are all missing,
 * it is expected that that is called out elsewhere. (Instead those fields would
 * just be returned as `null`.)
 */
export function parseDayGridRow(row: {
  [key: string]: string;
}): RowParseResult<ParsedDayGridRow> {
  // Convert keys to lower case because we don’t enforce capitalization of
  // column names.
  row = Object.fromEntries(
    Object.entries(row).map(([k, v]) => [k.toLowerCase(), v])
  );

  const parsedRow: ParsedDayGridRow = {
    lbjId: null,
    email: null,
    vanId: null,

    firstName: null,
    lastName: null,

    availabilityByDay: {},
  };

  // We check existance and non-falsey (in practical terms, non–empty string)
  // for these first two so that we don’t try to parse empty string as a number.
  //
  // The string-based values can be set to empty string if the columns were
  // provided.

  if (row['lbj id']) {
    parsedRow.lbjId = parseInt(row['lbj id']!);
  }

  if (row['van id']) {
    parsedRow.vanId = parseInt(row['van id']!);
  }

  if ('email' in row) {
    parsedRow.email = row['email']!;
  }

  if ('first name' in row) {
    parsedRow.firstName = row['first name']!;
  }

  if ('last name' in row) {
    parsedRow.lastName = row['last name']!;
  }

  for (const [col, value] of Object.entries(row)) {
    if (!isDateString(col)) {
      continue;
    }

    let availability: ParsedDayGridRow['availabilityByDay'][DateString];

    switch (value as DayGridCellValue) {
      case '':
        continue;

      case 'am':
        availability = {
          time: 'am',
          customTimes: null,
        };
        break;

      case 'pm':
        availability = {
          time: 'pm',
          customTimes: null,
        };
        break;

      case 'all day':
        availability = {
          time: 'all_day',
          customTimes: null,
        };
        break;

      // This is either the time range case or some random junk we won’t
      // recognize.
      default: {
        /** Separate time ranges, being gentle with ignoring whitespace */
        const ranges = value
          .split(',')
          .map((r) => r.trim())
          .filter((r) => r !== '');

        if (ranges.length === 0) {
          continue;
        }

        availability = {
          time: 'custom',
          customTimes: [],
        };

        for (const range of ranges) {
          // Matches "0830-1300" 24h time range, separating the hours from the
          // minutes for the two halves.
          const match = range.match(/^(\d{2})(\d{2})-(\d{2})(\d{2})$/);

          if (!match) {
            return {
              type: 'failure',
              message: `Unrecognized time range format: ${range}`,
            };
          }

          // We know customTimes exists because we initialized it just above.
          availability.customTimes!.push([
            `${match[1]}:${match[2]}:00` as TimeString,
            `${match[3]}:${match[4]}:00` as TimeString,
          ]);
        }
      }
    }

    parsedRow.availabilityByDay[col] = availability;
  }

  return {
    type: 'success',
    row: parsedRow,
  };
}

/**
 * Finds LBJ users associated with each row. If the LBJ ID is present in the
 * row, that is all that’s considered. Otherwise tries VAN ID and e-mail, in
 * that order.
 */
export function matchDayGridSignupRowsToUsers(
  rows: ParsedDayGridRow[],
  usersById: Immutable<Map<number, AssignmentUser>>
): {
  matched: [ParsedDayGridRow, number][];
  unmatched: ParsedDayGridRow[];
} {
  const userIdsByEmail = new Map<string, number>();
  const userIdsByVanId = new Map<number, number>();

  for (const user of usersById.values()) {
    userIdsByEmail.set(user.email.toLowerCase(), user.id);

    if (user.van_id) {
      userIdsByVanId.set(user.van_id, user.id);
    }
  }

  const matched: [ParsedDayGridRow, number][] = [];
  const unmatched: ParsedDayGridRow[] = [];

  for (const row of rows) {
    // If LBJ ID is specified, we require that it match a user. We don’t fall
    // back to email/vanID.
    if (row.lbjId !== null) {
      if (usersById.get(row.lbjId)) {
        matched.push([row, row.lbjId]);
      } else {
        unmatched.push(row);
      }
    } else {
      if (row.vanId !== null && userIdsByVanId.has(row.vanId)) {
        matched.push([row, userIdsByVanId.get(row.vanId)!]);
      } else if (
        row.email !== null &&
        userIdsByEmail.has(row.email.toLowerCase())
      ) {
        matched.push([row, userIdsByEmail.get(row.email.toLowerCase())!]);
      } else {
        unmatched.push(row);
      }
    }
  }

  return { matched, unmatched };
}

/**
 * Converts our availability row to a set of {@link NewApiVolunteerAvailability}
 * records, one for each day with availability.
 */
export function dayGridToAvailability(
  availabilityGroup: ApiVolunteerAvailabilityGroup,
  row: ParsedDayGridRow,
  userId: number
): NewApiVolunteerAvailability[] {
  return Object.entries(row.availabilityByDay).map(
    ([date, availability]): NewApiVolunteerAvailability => ({
      user_id: userId,
      // TS is not guaranteeing this as already a DateString, despite the type
      // of `availabilityByDay` keys being `DateString`.
      date: date as DateString,

      time: availability.time,
      custom_times: availability.customTimes,

      allowed_location_cities:
        availabilityGroup.default_allowed_location_cities,
      allowed_location_county_slugs:
        availabilityGroup.default_allowed_location_county_slugs,
      respect_travel_distance_tag:
        availabilityGroup.default_respect_travel_distance_tag,
    })
  );
}
