import _ from 'lodash';

import { State, UserRole } from '../constants';
import { FiltersBySection } from '../modules/filters/action-creators';
import { DeepMerge, MapFromJs } from '../utils/types';

import ApiClient from './api-client';
import { ApiAssignmentWithRelatedFields } from './assignment-service';
import { DateString, Iso8601String, OffsetResponse } from './common';
import { ApiBoilerRoom, ApiElection } from './lbj-shared-service';

/**
 * Base type of the user return by the API.
 *
 * Can be expanded to include additional fields.
 */
export type ApiUser = {
  id: number;
  first_name: string | null;
  last_name: string | null;
  last_logged_in: Iso8601String | null;
  role: UserRole;
  assignment_state: State;
  enabled: boolean;
};

export type ExpandApiUserPii = {
  email: string;
  phone_number: string | null;
  state: string | null;
  county: string | null;
  county_name: string | null;
  city: string | null;
  zipcode: string | null;
  address: string | null;
  invitation_status: 'new' | 'accepted';
  finished_training: boolean;
  tags: string[];
  credential_status: 'none' | 'credentialed';
  signed_eula: boolean;
  confirmed_email: boolean;
  distance: null;
  max_distance_miles?: number;
  coordinates: [number, number] | null;
  van_id: number | null;
  van_state: string | null;
};

export type ExpandApiUserEmailsReceived = {
  emails_received: {
    sent_by: number | null;
    sent_to: number;
    sent_at: Iso8601String | null;
    subject: string;
    message: string;
    // Do we need a more fixed type on this?
    template: string;
  }[];
};

export type ExpandApiUserRelated = {
  related: ApiAssignmentWithRelatedFields[];
};

export type WithApiUserCurrentBoilerRooms = {
  current_boiler_rooms: ApiBoilerRoom[];
};

export type ApiUserElection = {
  id: number;
  election: ApiElection;
  expiration_date: DateString | null;
  active: boolean;
  role: UserRole;
};

export type ExpandApiUserElectionsPii = {
  user_elections: ApiUserElection[];
};

/**
 * Record that tracks how `expand` values map to new types.
 *
 * We make this its own helper type because `getUserList` is called with
 * different values for `expand`.
 */
export type ApiUserExpandedFields = {
  pii: ExpandApiUserPii;
  related: ExpandApiUserRelated;
  'pii,related': ExpandApiUserPii & ExpandApiUserRelated;
  'elections.pii': ExpandApiUserElectionsPii;
  emails_received: ExpandApiUserEmailsReceived;
};

export type ApiCurrentUser = Awaited<ReturnType<typeof getCurrentUser>>['user'];

// TODO(fiona): Make this better once we can get Immutable to 4.1 and maybe
// switch to Records.
export type User = MapFromJs<ApiUser> | MapFromJs<ApiCurrentUser>;

/**
 * Returns detailed information on the currently logged-in user.
 *
 * Is not called with an `expands` param out in the wild so we can hard-code the
 * response type.
 */
export function getCurrentUser(params: { dates?: string } = {}) {
  const query = _.assign({ expand: 'related,pii,elections.pii' }, params);

  return ApiClient<{
    user: DeepMerge<
      [
        ApiUser & {
          first_login: boolean;
        },
        // current_boiler_rooms seems to be added by default
        WithApiUserCurrentBoilerRooms,
        ExpandApiUserRelated,
        ExpandApiUserPii,
        ExpandApiUserElectionsPii
      ]
    >;
  }>('current_user/', 'GET', query);
}

/**
 * Fetches the number of users who match the given query.
 */
export function getUserCount(query = {}) {
  return ApiClient<{ count: number }>('users/count/', 'GET', query);
}

export type ApiGetUserListFilters<Only extends 'id' | undefined> = DeepMerge<
  [
    Partial<FiltersBySection['USER']>,
    {
      only?: Only;
      state?: State | '' | undefined;
      currently_assigned_to_boiler_room?: string | number;
      name_prefix?: string | undefined;
      size?: number | string;
      offset?: number | string;
      email__isnull?: boolean;
      /** comma-separated list of user IDs */
      id?: string | undefined;
    }
  ]
>;

/**
 * Returns information about all users that match the provided State.
 *
 * Callers should parameterize “Expands” type based on the value that’s passed
 * in for `expand`.
 *
 * If the `only` filter is set to `id`, this function returns objects that just
 * have an `id` value.
 */
export function getUserList<
  Expands extends keyof ApiUserExpandedFields = 'pii',
  Only extends 'id' | undefined = undefined
>(filters: ApiGetUserListFilters<Only> & { expand?: string } = {}) {
  const query = _.assign({ expand: 'pii' }, filters);

  return ApiClient<
    OffsetResponse<
      'users',
      Only extends 'id'
        ? Pick<ApiUser, 'id'> | number
        : ApiUser & ApiUserExpandedFields[Expands]
    >
  >('users/', 'GET', query);
}

export function updateUser<Expands extends keyof ApiUserExpandedFields = 'pii'>(
  userId: number,
  userDetails: Partial<ApiUser & ApiUserExpandedFields[Expands]>,
  filters = {}
) {
  const query = _.assign({ expand: 'pii' }, filters);
  return ApiClient<{ user: ApiUser }>(
    `user/${userId}/`,
    'PUT',
    query,
    {},
    userDetails
  );
}

/**
 * Returns information about a specific user.
 *
 * Shares the same “Expands” type checking with {@link getUserList}.
 */
export function getUserDetails<
  Expands extends keyof ApiUserExpandedFields = 'pii'
>(
  userId: number,
  filters: { dates?: string | undefined; expand?: string } = {}
) {
  const query = _.assign({ expand: 'pii' }, filters);

  return ApiClient<{
    user: ApiUser &
      // current_boiler_rooms seems to be added by default
      WithApiUserCurrentBoilerRooms &
      ApiUserExpandedFields[Expands];
  }>(`user/${userId}/`, 'GET', query);
}

export function bulkTagUsers(userIds: number[], tags: string[]) {
  const payload = { tags, user_ids: userIds };
  return ApiClient(`user_tags/bulk/`, 'POST', {}, {}, payload);
}

export function cloneUsers(userIds: number[], electionId: number) {
  const payload = { clone_to_election_id: electionId, user_ids: userIds };
  return ApiClient<{
    cloned_users: unknown[];
    already_existing_users: unknown[];
  }>(`users/clone/`, 'POST', {}, {}, payload);
}

export function createUser(
  params: Omit<
    ApiUser,
    'id' | 'last_logged_in' | 'assignment_state' | 'enabled'
  > & {
    election_id: number;
    /** Set this to update an existing user with the same email address. */
    confirmed?: boolean;
  }
) {
  return ApiClient<{ user: ApiUser }>(`user/`, 'POST', {}, {}, params);
}

export function disableUsers(userIds: number[]) {
  const payload = { user_ids: userIds };
  return ApiClient(`users/disable/`, 'POST', {}, {}, payload);
}

export default {
  getCurrentUser,
  getUserCount,
  getUserList,
  createUser,
  updateUser,
  getUserDetails,
  bulkTagUsers,
  cloneUsers,
  disableUsers,
};
