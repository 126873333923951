import { Immutable } from 'immer';
import React from 'react';

import { ActionButton } from '../../../components/form';
import { isDateString } from '../../../services/common';
import {
  ApiVolunteerAvailabilityGroup,
  NewApiVolunteerAvailability,
} from '../../../services/volunteer-availability-service';
import * as Sets from '../../../utils/sets';
import { assertUnreachable, Awaitable } from '../../../utils/types';

import { AssignmentUser } from '../assignment-state';

import type { VolunteerAvailabilityCsvFormat } from './VolunteerAvailabilityDialog';
import {
  dayGridToAvailability,
  matchDayGridSignupRowsToUsers,
  parseDayGridRow,
  ParsedDayGridRow,
} from './day-grid-signup-format';
import {
  matchVanSignupRowsToUsers,
  mergeVanSignupTimes,
  ParsedVanSignupRow,
  parseVanSignupRow,
  uniqueVanUsersFromSignups,
  vanSignupToAvailability,
  VAN_SIGNUP_ROW_FIELDS,
} from './van-signup-format';

export type UploadAvailabilityFn = (
  availabilityGroup: ApiVolunteerAvailabilityGroup,
  availability: NewApiVolunteerAvailability[]
) => Awaitable<void>;

/**
 * Component for showing the parsed results from the availability CSV upload.
 */
const VolunteerAvailabilityDialogResults: React.FunctionComponent<{
  usersById: Immutable<Map<number, AssignmentUser>>;
  availabilityGroup: ApiVolunteerAvailabilityGroup;

  isParsing: boolean;
  csvFormat: VolunteerAvailabilityCsvFormat;
  csvResults: Papa.ParseResult<{ [key: string]: string }>;
  csvFileName: string | null;

  onBack: () => void;
  uploadAvailability: UploadAvailabilityFn;
}> = ({
  csvFormat,
  csvResults,
  usersById,
  availabilityGroup,
  onBack,
  uploadAvailability,
}) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const csvInterpretation = React.useMemo(
    () => interpretAvailabilityCsv(csvFormat, csvResults, usersById),
    [csvFormat, csvResults, usersById]
  );

  return (
    <div className="flex flex-1 flex-col gap-4 overflow-hidden">
      {csvInterpretation.type === 'no-rows' && (
        <div>No rows found in the CSV</div>
      )}

      {csvInterpretation.type === 'bad-schema' && (
        <div className="flex flex-1 flex-col overflow-hidden">
          There was a problem with the format of your CSV:{' '}
          {csvInterpretation.message}
        </div>
      )}

      {csvInterpretation.type === 'bad-format' && (
        <div className="flex flex-1 flex-col overflow-hidden">
          Errors processing the CSV:
          <div className="overflow-auto">
            {csvInterpretation.problems.map(([rowIdx, message], idx) => (
              <div key={idx}>
                {/* +1 to change to 1-index, +1 for the CSV header */}
                <b>{rowIdx + 2}:</b> {message}
              </div>
            ))}
          </div>
        </div>
      )}

      {(csvInterpretation.type === 'van-signup-success' ||
        csvInterpretation.type === 'day-grid-success') && (
        <div className="flex flex-1 flex-col gap-4 overflow-hidden">
          <div>
            Matched{' '}
            <strong>{csvInterpretation.rowAndUserId.length} signups</strong> to
            volunteers on this election.
          </div>

          {csvInterpretation.unmatchedRows.length > 0 && (
            <>
              <div>
                <b>Warning!</b> Could not find the following people in this
                election by e-mail or VAN Id:
              </div>

              <div className="flex-1 overflow-auto">
                {csvInterpretation.type === 'van-signup-success' &&
                  uniqueVanUsersFromSignups(
                    csvInterpretation.unmatchedRows
                  ).map((row, idx) => (
                    <div key={idx}>
                      {row.Name} ({row.Email}) – ID: {row.VanID ?? <i>none</i>}
                    </div>
                  ))}

                {csvInterpretation.type === 'day-grid-success' &&
                  csvInterpretation.unmatchedRows.map((row, idx) => (
                    <div key={idx}>
                      {row.firstName} {row.lastName}{' '}
                      {row.email && `(${row.email})`}
                      {row.vanId !== null && ` — VAN ID: ${row.vanId}`}
                    </div>
                  ))}
              </div>
            </>
          )}

          {uploadError && (
            <div>
              <strong>Error during upload:</strong> {uploadError}
            </div>
          )}
        </div>
      )}

      <div className="flex justify-end gap-2">
        <ActionButton role="secondary" onPress={() => onBack()}>
          Go Back
        </ActionButton>

        <ActionButton
          isDisabled={
            (csvInterpretation.type !== 'van-signup-success' &&
              csvInterpretation.type !== 'day-grid-success') ||
            isUploading
          }
          onPress={async () => {
            let availability: NewApiVolunteerAvailability[];

            switch (csvInterpretation.type) {
              case 'van-signup-success':
                availability = csvInterpretation.rowAndUserId.map(
                  ([row, userId]) =>
                    vanSignupToAvailability(availabilityGroup, row, userId)
                );
                break;

              case 'day-grid-success':
                availability = csvInterpretation.rowAndUserId.flatMap(
                  ([row, userId]) =>
                    dayGridToAvailability(availabilityGroup, row, userId)
                );
                break;

              default:
                // Shouldn’t have gotten here anyway, the button should have
                // been disabled.
                return;
            }

            try {
              setIsUploading(true);
              setUploadError(null);
              await uploadAvailability(availabilityGroup, availability);
            } catch (e) {
              setUploadError(`${e}`);
            } finally {
              setIsUploading(false);
            }
          }}
        >
          Upload Availability
        </ActionButton>
      </div>
    </div>
  );
};

export default VolunteerAvailabilityDialogResults;

export type AvailabilityCsvInterpretation =
  | {
      type: 'van-signup-success';
      rowAndUserId: [ParsedVanSignupRow, number][];
      unmatchedRows: ParsedVanSignupRow[];
    }
  | {
      type: 'day-grid-success';
      rowAndUserId: [ParsedDayGridRow, number][];
      unmatchedRows: ParsedDayGridRow[];
    }
  | { type: 'no-rows' }
  | { type: 'bad-schema'; message: string }
  | { type: 'bad-format'; problems: [number, string][] };

/**
 * Returns success or errors based on the CSV data, and also handles matching to
 * LBJ users.
 *
 * Right now our two input formats have separate intermediate “parsed” formats.
 *
 * TODO(fiona): When adding more formats, might be a good idea to harmonize the
 * intermediate representation across all of them so that there are fewer
 * separate cases downstream of this function.
 */
export function interpretAvailabilityCsv(
  csvFormat: VolunteerAvailabilityCsvFormat,
  results: Papa.ParseResult<{ [key: string]: string }>,
  usersById: Immutable<Map<number, AssignmentUser>>
): AvailabilityCsvInterpretation {
  if (results.data.length === 0) {
    return { type: 'no-rows' };
  }

  switch (csvFormat) {
    case 'van-signup': {
      // Quick check to make sure that the overall CSV has the right columns,
      // since that should be shown as a global error instead of per-row.
      const csvFields = results.meta.fields ?? [];

      const missingSchemaFields = Sets.difference(
        new Set(VAN_SIGNUP_ROW_FIELDS),
        new Set(csvFields)
      );

      if (missingSchemaFields.size > 0) {
        return {
          type: 'bad-schema',
          message: `Missing columns: ${[...missingSchemaFields].join(', ')}`,
        };
      }

      const successRows: ParsedVanSignupRow[] = [];
      const problemRowPairs: [number, string][] = [];

      results.data.map(parseVanSignupRow).forEach((parsedResult, idx) => {
        switch (parsedResult.type) {
          case 'success':
            successRows.push(parsedResult.row);
            break;

          case 'failure':
            problemRowPairs.push([idx, parsedResult.message]);
            break;

          default:
            assertUnreachable(parsedResult);
        }
      });

      if (problemRowPairs.length > 0) {
        return { type: 'bad-format', problems: problemRowPairs };
      }

      const mergedTimeRows = mergeVanSignupTimes(successRows);

      const { matched, unmatched } = matchVanSignupRowsToUsers(
        mergedTimeRows,
        usersById
      );

      return {
        type: 'van-signup-success',
        rowAndUserId: matched,
        unmatchedRows: unmatched,
      };
    }

    case 'day-grid': {
      // Quick check to make sure that the overall CSV has the columns we need
      // for user matching, and at least one date column, since that should be
      // shown as a global error instead of per-row.
      //
      // For custom CSVs like this we don’t want to enforce capitalization
      // standards on column names, so we lowercase everything.
      const csvFields = (results.meta.fields ?? []).map((s) => s.toLowerCase());

      if (
        !csvFields.includes('email') &&
        !csvFields.includes('van id') &&
        !csvFields.includes('lbj id')
      ) {
        return {
          type: 'bad-schema',
          message: 'CSV must include an Email, VAN ID, or LBJ ID column',
        };
      }

      if (!csvFields.find((col) => isDateString(col))) {
        return {
          type: 'bad-schema',
          message: 'CSV must have at least one column in YYYY-MM-DD format',
        };
      }

      const successRows: ParsedDayGridRow[] = [];
      const problemRowPairs: [number, string][] = [];

      results.data.map(parseDayGridRow).forEach((parsedResult, idx) => {
        switch (parsedResult.type) {
          case 'success':
            successRows.push(parsedResult.row);
            break;

          case 'failure':
            problemRowPairs.push([idx, parsedResult.message]);
            break;
        }
      });

      if (problemRowPairs.length > 0) {
        return { type: 'bad-format', problems: problemRowPairs };
      }

      const { matched, unmatched } = matchDayGridSignupRowsToUsers(
        successRows,
        usersById
      );

      return {
        type: 'day-grid-success',
        rowAndUserId: matched,
        unmatchedRows: unmatched,
      };
    }

    default:
      assertUnreachable(csvFormat);
  }
}

export type RowParseResult<T> =
  | { type: 'success'; row: T }
  | { type: 'failure'; message: string };
